import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { SliderModule } from 'primeng/slider';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'rqa-map-time',
  templateUrl: './map-time.component.html',
  styleUrls: ['./map-time.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent, FormsModule, SliderModule]
})
export class MapTimeComponent {
  @Input()
  set filterTimeRange(value: string[]) {
    if (value) {
      this.timeRange = [this.parseTime(value[0]), this.parseTime(value[1], true)];
      this.onTimeRangeChangeComplete(false);
    }
  }
  @Output() timeRangeChange = new EventEmitter<string[]>();
  timeRange: number[] = [0, 1440];
  formattedStartTime: string = '06:00';
  formattedEndTime: string = '06:00';
  tooltipPositionMin: number = 0;
  tooltipPositionMax: number = 100;
  tooltipsVisible: boolean = false;

  onTimeRangeChange(): void {
    this.tooltipsVisible = true;
    this.formattedStartTime = this.formatTime(this.timeRange[0]);
    this.formattedEndTime = this.formatTime(this.timeRange[1]);
    this.updateTooltips();
  }

  onTimeRangeChangeComplete(emit: boolean): void {
    this.tooltipsVisible = false;
    if (emit) {
      this.timeRangeChange.emit([this.formattedStartTime, this.formattedEndTime]);
      this.tooltipsVisible = true;
    }
  }

  private updateTooltips(): void {
    this.tooltipPositionMin = (this.timeRange[0] / 1440) * 100;
    this.tooltipPositionMax = (this.timeRange[1] / 1440) * 100;
  }

  private formatTime(value: number): string {
    const totalMinutes = (value + 360) % 1440;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${this.padZero(hours)}:${this.padZero(minutes)}`;
  }

  private parseTime(time: string, isMaxTime?: boolean): number {
    const timeRegex = /^[0-9]{2}:[0-9]{2}$/;
    if (timeRegex.test(time)) {
      if (isMaxTime && time === '06:00') {
        return 1440;
      }
      const [hours, minutes] = time.split(':').map(Number);
      return (hours * 60 + minutes - 360 + 1440) % 1440;
    }
    return isMaxTime ? 1440 : 0;
  }

  private padZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
