import { Observable } from 'rxjs';
import {
  AupDatesResponse,
  CancelledRqa,
  ReservationHistory,
  RqaReservationChangeDTO,
  RqaReservationDTO,
  ValidatedCancelDTO
} from '../model/reservation.model';
import { CancelReservationResponse } from '../model/orders';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

const url = `/api/rqa`;

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  getSentReservations(): Observable<RqaReservationDTO[]> {
    return this.http.get<RqaReservationDTO[]>(`${url}`);
  }

  getCancelledReservations(): Observable<CancelledRqa[]> {
    return this.http.get<CancelledRqa[]>(`${url}/cancelled`);
  }

  cancelReservations(reservationUuids: string[]): Observable<CancelReservationResponse> {
    return this.http.post<CancelReservationResponse>(`${url}/reservation/cancel`, reservationUuids);
  }

  editSentOrders(reservationUuids: RqaReservationChangeDTO[]): Observable<RqaReservationChangeDTO[]> {
    return this.http.post<RqaReservationChangeDTO[]>(`${url}/reservation/change`, reservationUuids);
  }

  validateCancellation(reservationUuids: string[]): Observable<ValidatedCancelDTO> {
    return this.http.post<ValidatedCancelDTO>(`${url}/reservation/cancel/validate`, reservationUuids);
  }

  getAupDates(): Observable<AupDatesResponse[]> {
    return this.http.get<AupDatesResponse[]>(`${url}/aupList`);
  }

  getFile(aupDate: string): Observable<HttpResponse<ArrayBuffer>> {
    return this.http.get(`${url}/file?aupDate=${aupDate}`, { observe: 'response', responseType: 'arraybuffer' });
  }

  getReservationHistory(uuid: string): Observable<ReservationHistory[]> {
    return this.http.get<ReservationHistory[]>(`/api/history/${uuid}`);
  }
}
