<div class="full-container pt-5 lg:pr-5 lg:pl-5 w-full pb-6" *ngIf="aupDetails() as details">
  <section class="flex justify-content-between mb-5 align-items-center px-3 lg:px-0">
    <div>
      <h1 class="text-2xl mx-0 my-0 title flex align-items-center">
        <p-button icon="pi pi-arrow-left" [rounded]="true" [text]="true" severity="secondary" (click)="goBack()" />{{
          details.name
        }}
        {{ details.dateFrom }} - {{ details.dateTo }}
      </h1>
    </div>
  </section>
  <section>
    <p-table
      #dt
      [value]="reservations()"
      [styleClass]="'pansa-table'"
      [scrollable]="true"
      scrollHeight="500px"
      [responsive]="true"
      responsiveLayout="stack"
      sortMode="single"
      (sortFunction)="customSort($event)"
      [customSort]="true"
      [breakpoint]="'960px'"
    >
      <ng-template pTemplate="caption">
        <form [formGroup]="filtersForm" class="flex flex-column align-items-center">
          <div class="flex gap-1 pt-3 pb-2 w-full">
            <p-floatLabel class="w-full">
              <p-multiSelect
                inputId="designator"
                [options]="designatorOptions"
                formControlName="designator"
                display="chip"
                styleClass="w-full"
                [maxSelectedLabels]="filtersForm.get(designator)?.length"
                panelStyleClass="w-15rem"
              />
              <label for="designator">{{ 'aup.table.designator' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="flex gap-1 pt-3 pb-2 w-full">
            <p-floatLabel class="w-full">
              <p-multiSelect
                inputId="responsibleUnit"
                [options]="unitOptions"
                formControlName="responsibleUnit"
                display="chip"
                styleClass="w-full"
                [maxSelectedLabels]="filtersForm.get(responsibleUnit)?.length"
                panelStyleClass="w-15rem z-5"
              />
              <label for="responsibleUnit">{{ 'aup.table.responsibleUnit' | translate }}</label>
            </p-floatLabel>
          </div>
          <div class="flex gap-1 pt-3 w-full">
            <p-floatLabel class="w-3">
              <p-inputMask id="from" mask="99:99" formControlName="from" />
              <label for="from">{{ 'aup.table.from' | translate }}</label>
            </p-floatLabel>
            <p-floatLabel class="w-3">
              <p-inputMask id="until" mask="99:99" formControlName="until" />
              <label for="until">{{ 'aup.table.until' | translate }}</label>
            </p-floatLabel>
            <p-floatLabel class="w-3">
              <p-autoComplete
                appendTo="body"
                formControlName="flMin"
                [suggestions]="filteredAltitudes"
                (completeMethod)="searchAltitude($event)"
                [completeOnFocus]="true"
              ></p-autoComplete>
              <label for="flMin">{{ 'aup.table.flMin' | translate }}</label>
            </p-floatLabel>

            <p-floatLabel class="w-3">
              <p-autoComplete
                appendTo="body"
                formControlName="flMax"
                [suggestions]="filteredAltitudes"
                (completeMethod)="searchAltitude($event)"
                [completeOnFocus]="true"
              ></p-autoComplete>
              <label for="flMax">{{ 'aup.table.flMax' | translate }}</label>
            </p-floatLabel>
            <p-button
              icon="pi pi-filter-slash"
              severity="danger"
              [rounded]="true"
              [text]="true"
              (click)="clearFilters()"
            />
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="text-xs">
          <th class="text-xs" pSortableColumn="designator">
            {{ 'aup.table.designator' | translate }} <p-sortIcon field="designator"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="flMin">
            {{ 'aup.table.flMin' | translate }} <p-sortIcon field="flMin"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="flMax">
            {{ 'aup.table.flMax' | translate }} <p-sortIcon field="flMax"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="from">
            {{ 'aup.table.from' | translate }} <p-sortIcon field="from"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="until">
            {{ 'aup.table.until' | translate }} <p-sortIcon field="until"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="responsibleUnit">
            {{ 'aup.table.responsibleUnit' | translate }} <p-sortIcon field="responsibleUnit"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="remarks">
            {{ 'aup.table.remarks' | translate }} <p-sortIcon field="remarks"></p-sortIcon>
          </th>
          <th class="text-xs" pSortableColumn="status" *ngIf="details.name === 'UUP'">
            {{ 'aup.table.status' | translate }} <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th class="p-0">
            <p-button
              icon="pi pi-sort-alt-slash"
              [rounded]="true"
              [text]="true"
              severity="danger"
              (click)="clearSort()"
            />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded">
        <tr class="text-sm">
          <td>
            <span class="p-column-title">{{ 'aup.table.designator' | translate }}</span>
            {{ item.designator }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.flMin' | translate }}</span>
            {{ item.flMin }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.flMax' | translate }}</span>
            {{ item.flMax }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.from' | translate }}</span>
            {{ item.from }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.until' | translate }}</span>
            {{ item.until }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.responsibleUnit' | translate }}</span>
            {{ item.responsibleUnit }}
          </td>
          <td>
            <span class="p-column-title">{{ 'aup.table.remarks' | translate }}</span>
            {{ item.remarks }}
          </td>
          <td *ngIf="details.name === 'UUP'" class="py-1">
            <span class="p-column-title">{{ 'aup.table.status' | translate }}</span>
            <rqa-status-info [statusName]="item.status" class="flex" />
          </td>
          <td></td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <div class="p-2">
          {{ 'primeng.emptyFilterMessage' | translate }}
        </div>
      </ng-template>
    </p-table>
  </section>
</div>
