export enum Route {
  HOME = '',
  ORDERS = 'orders',
  CANCELED_ORDERS = 'orders/canceled',
  TEMPLATES = 'templates',
  TEMPLATES_EDIT = 'templates/edit',
  OBSERVER = 'observer',
  AUP = 'observer/aup',
  UUP = 'observer/uup'
}
