import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ReservationService } from 'src/app/services/reservation.service';
import { DateService } from 'src/app/services/date.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'rqa-load-template-dialog',
  templateUrl: './load-template-dialog.component.html',
  styleUrls: ['./load-template-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CalendarModule, ButtonModule, TranslateModule],
  providers: []
})
export class LoadTemplateDialogComponent implements OnInit {
  private ref = inject(DynamicDialogRef);
  private reservationService = inject(ReservationService);
  private dateService = inject(DateService);
  private translateService = inject(TranslateService);
  private primengConfig = inject(PrimeNGConfig);
  date: Date;
  minDate: Date;
  maxDate: Date;

  ngOnInit(): void {
    this.translateService.get('primeng').subscribe((res) => this.primengConfig.setTranslation(res));
    this.getReservableInterval();
  }

  save(): void {
    this.ref.close(this.date);
  }

  close(): void {
    this.ref.close();
  }

  private getReservableInterval() {
    const commonInterval = this.reservationService.commonDateInterval();
    if (commonInterval) {
      this.minDate = this.dateService.parseIsoDateToDateObj(commonInterval.startDate);
      this.maxDate = this.dateService.parseIsoDateToDateObj(commonInterval.endDate);
      this.date = this.minDate;
    }
  }
}
