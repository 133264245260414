import { Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { Table, TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObserverService } from 'src/app/services/observer.service';
import { InputTextModule } from 'primeng/inputtext';
import { Observable } from 'rxjs';
import { AupItem } from 'src/app/model/observer.model';
import { Router } from '@angular/router';
import { Route } from 'src/app/model/route.enum';
import { StatusInfoComponent } from 'src/app/ui/status-info/status-info.component';

@Component({
  selector: 'rqa-observer',
  templateUrl: './observer.component.html',
  styleUrls: ['./observer.component.scss'],
  standalone: true,
  imports: [
    InputTextModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    TableModule,
    TranslateModule,
    StatusInfoComponent
  ]
})
export class ObserverComponent {
  @ViewChild('dt') pTable!: Table;
  private observerService = inject(ObserverService);
  private router = inject(Router);
  aupList$: Observable<AupItem[]> = this.observerService.fetchAupList();

  showDetails(item: AupItem): void {
    const dateParam = item.dateFrom.slice(0, 10);
    const route = item.name === 'AUP' ? Route.AUP : Route.UUP;
    this.router.navigate([`${route}/${dateParam}`]);
  }
}
