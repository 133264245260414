import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitterComponent } from 'src/app/ui/splitter/splitter.component';
import { SelectedMapLayer } from 'src/app/model/map.model';

@Component({
  selector: 'rqa-selected-map-layers',
  templateUrl: './selected-map-layers.component.html',
  styleUrls: ['./selected-map-layers.component.scss'],
  standalone: true,
  imports: [CommonModule, SplitterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedMapLayersComponent {
  @Input() layers: SelectedMapLayer[];
}
