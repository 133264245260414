import { AirspaceElement } from './map.model';

export interface ReservationError {
  uuid?: string;
  field: string;
  message: string;
  type?: string;
}

export interface AupDatesResponse {
  date: string;
  range: string;
}

export interface ReservationDTO {
  activityType?: string;
  designator: string;
  endDate: string | Date;
  errors?: ReservationError[];
  lowerAltitude: string;
  startDate: string | Date;
  upperAltitude: string;
  uuid?: string;
  warnings?: string[];
  tempId?: string;
}

export interface EditMultipleDates {
  startDate: string;
  endDate: string;
  uuidList: string[];
}

export interface UserConfig {
  name: string;
  email: string;
  timeZone: string;
  phoneNumbers: string[];
  reservableAirspaceElementTypes: string[];
  isDraw: boolean;
  locale: string;
  verified: boolean;
}

export interface LanguageDTO {
  locale: string;
}

export interface RqaStatus {
  code: string;
  header: string;
  message: string;
}

export interface RqaCreateDTO {
  phoneNumber: string;
  remarks: string;
}

export interface TimeRange {
  startDate: string;
  endDate: string;
}

export interface CancelledRqa {
  designator: string;
  endDate: string;
  lowerAltitude: string;
  startDate: string;
  status: string;
  upperAltitude: string;
  uuid: string;
  activityType: string;
  reservationHistory?: ReservationHistoryCanceled[];
}

export class ValidatedCancelDTO {
  warning: string;
}

export interface PageProperties {
  sort: string;
  page: number;
  field: string;
}

export interface FormLayerSelect {
  type: LayerType;
  layer: AirspaceElement;
}

export interface WebrqaResponse {
  value: string;
}

export interface Altitudes {
  lower: string;
  upper: string;
}

export interface RqaReservationDTO {
  uuid: string;
  designator: string;
  status: string;
  startDate: string;
  endDate: string;
  lowerAltitude: string;
  upperAltitude: string;
  activityType: string;
  subReservationDTOList: SubReservationDTO[];
  reservationHistory?: ReservationHistory[];
  open: boolean;
  selected?: boolean;
}

export interface RqaReservationChangeDTO {
  uuid: string;
  designator: string;
  status: string;
  startDate: string;
  endDate: string;
  lowerAltitude: string;
  upperAltitude: string;
  activityType: string;
  changed: boolean;
}

export interface SubReservationDTO {
  uuid: string;
  startDate: string;
  endDate: string;
  status: string;
  cancelable: boolean;
  selected?: boolean;
}

export interface ReservationHistory {
  eventDate: Date;
  historyEvent: string;
}

export interface ReservationHistoryCanceled {
  sentDate: Date;
  cancelledDate: Date;
}

export enum HistoryEventEnum {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED'
}

export enum LayerType {
  CLEAR_SELECTION = 'clearSelection',
  CLEAR_LAYERS = 'clearLayers',
  SELECT = 'select',
  WATCH = 'watch',
  SHOW_ALL = 'showAll'
}
