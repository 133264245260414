<rqa-header></rqa-header>
<div class="relative w-full holidays-info">
  <rqa-holidays-info></rqa-holidays-info>
</div>
<div class="loader flex flex-column align-items-center justify-content-center w-full" *ngIf="loading">
  <p-progressSpinner ariaLabel="loading" />
</div>

<div>
  <router-outlet></router-outlet>
</div>

<p-toast position="bottom-center"></p-toast>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
