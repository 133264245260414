import { ChangeDetectionStrategy, Component, OnInit, Signal, ViewChild, effect, inject } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObserverService } from 'src/app/services/observer.service';
import { Table, TableModule } from 'primeng/table';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  AupDetails,
  AupReservation,
  AupReservationFilters,
  AupReservationFiltersFormGroup
} from 'src/app/model/observer.model';
import { ActivatedRoute } from '@angular/router';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { altitudeArrayObserver } from 'src/app/model/altitudes.model';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { SortEvent } from 'primeng/api';
import { StatusInfoComponent } from 'src/app/ui/status-info/status-info.component';

@Component({
  selector: 'rqa-aup-reservations',
  templateUrl: './aup-reservations.component.html',
  styleUrls: ['./aup-reservations.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    TableModule,
    FloatLabelModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputMaskModule,
    StatusInfoComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AupReservationsComponent implements OnInit {
  @ViewChild('dt') pTable!: Table;
  private route = inject(ActivatedRoute);
  private observerService = inject(ObserverService);
  private fb = inject(FormBuilder);
  private _location = inject(Location);

  filtersForm: AupReservationFiltersFormGroup = this.fb.group({
    designator: this.fb.control<string[]>([]),
    responsibleUnit: this.fb.control<string[]>([]),
    from: this.fb.control<string>(''),
    until: this.fb.control<string>(''),
    flMin: this.fb.control<string | null>(null),
    flMax: this.fb.control<string | null>(null)
  }) as AupReservationFiltersFormGroup;

  readonly filters: Signal<AupReservationFilters> = this.observerService.filtersSignal;

  aupDetails: Signal<AupDetails> = this.observerService.aupDetails;
  aupDate: string | null = this.route.snapshot.paramMap.get('date');
  designatorOptions: string[] = [];
  unitOptions: string[] = [];
  altitudes: string[] = altitudeArrayObserver;
  filteredAltitudes: string[] = [];
  readonly reservations: Signal<AupReservation[]> = this.observerService.filteredReservation;
  isEmited = false;

  constructor() {
    effect(
      () => {
        if (this.filters() && !this.isEmited) {
          this.filtersForm.get('from')?.setValue(this.filters().from, { emitEvent: false });
          this.filtersForm.get('until')?.setValue(this.filters().until, { emitEvent: false });
          this.filtersForm.get('flMin')?.setValue(this.filters().flMin, { emitEvent: false });
          this.filtersForm.get('flMax')?.setValue(this.filters().flMax, { emitEvent: false });
        }
      },
      { allowSignalWrites: true }
    );
    effect(() => {
      if (this.reservations().length) {
        if (!this.filtersForm.get('designator')?.value || this.filtersForm.get('designator')?.value.length === 0) {
          this.updateDesignatorOptions();
        }
        if (
          !this.filtersForm.get('responsibleUnit')?.value ||
          this.filtersForm.get('responsibleUnit')?.value.length === 0
        ) {
          this.updateUnitOptions();
        }
      }
    });
  }

  ngOnInit(): void {
    this.fetchAupDetails();
    this.onFiltersFormChange();
  }

  goBack(): void {
    this._location.back();
  }

  searchAltitude(event: AutoCompleteCompleteEvent): void {
    const query = event.query.toLowerCase();
    this.filteredAltitudes = this.altitudes.filter((el) => el.toLowerCase().includes(query));
  }

  clearFilters(): void {
    this.filtersForm.reset();
  }

  clearSort(): void {
    this.observerService.updateSort(null);
    this.pTable.reset();
  }

  customSort(event: SortEvent) {
    const currentSort = this.observerService.sortSignal();
    if (!currentSort || currentSort.field !== event.field || currentSort.order !== event.order) {
      setTimeout(() => {
        this.observerService.updateSort(event);
      }, 100);
    }
  }

  private fetchAupDetails(): void {
    this.observerService
      .fetchAupDetails([
        {
          localDate: this.aupDate || ''
        }
      ])
      .subscribe();
  }

  private onFiltersFormChange(): void {
    this.filtersForm.valueChanges.subscribe(() => {
      this.isEmited = true;
      const filtersValue = this.filtersForm.getRawValue();
      if (Object.values(filtersValue).some((value) => value !== null)) {
        this.observerService.updateFilters(filtersValue);
      } else {
        this.observerService.updateFilters(null);
      }
      setTimeout(() => {
        this.isEmited = false;
      }, 200);
    });
  }

  private updateUnitOptions(): void {
    this.unitOptions = [...new Set(this.reservations().map((item) => item.responsibleUnit))];
  }

  private updateDesignatorOptions(): void {
    this.designatorOptions = [...new Set(this.reservations().map((item) => item.designator))].sort((a, b) =>
      a.localeCompare(b)
    );
  }
}
