<div class="height-bar p-2 mx-1">
  <div class="flex justify-content-between">
    <h3 class="mt-0">{{ 'map.filters.height' | translate }}</h3>
  </div>
  <div class="slider p-2 flex justify-content-end">
    <p-slider
      [(ngModel)]="heightRange"
      [range]="true"
      orientation="vertical"
      [min]="0"
      [max]="alititudeArray.length - 1"
      [step]="1"
      [style]="{ height: '32rem' }"
      (onChange)="onHeightRangeChange()"
      (onSlideEnd)="onHeightRangeChangeComplete()"
    />
    <div class="relative">
      <div class="slider-tooltip" [style.bottom.%]="tooltipPositionMin" [style.transform]="'translateY(+50%)'">
        {{ minHeight }}
      </div>
      <div class="slider-tooltip" [style.bottom.%]="tooltipPositionMax" [style.transform]="'translateY(+50%)'">
        {{ maxHeight }}
      </div>
    </div>
  </div>
</div>
