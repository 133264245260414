import { ChangeDetectionStrategy, Component, DestroyRef, effect, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import * as Turf from '@turf/turf';
import {
  AirspaceElement,
  AupGeoJSONOptions,
  Coordinates,
  FilterLayer,
  Geometry,
  MapLayerTypeEnum,
  WebrqaGeoJSONOptions
} from '../model/map.model';
import { CookieService } from 'ngx-cookie-service';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogManagerService } from '../services/dialog-manager.service';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonComponent } from '../ui/toggle-button/toggle-button.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { IconComponent } from '../ui/icon/icon.component';
import { AccordionModule } from 'primeng/accordion';
import { SearchMapDialogComponent } from './search-map-dialog/search-map-dialog.component';
import { MapService } from './map.service';
import { Position } from '@turf/turf';
import { setHiglightStyle } from './layersStyle';
import { Style, StyleType } from '../model/layers.model';

@Component({
  selector: 'rqa-map',
  templateUrl: './map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonModule,
    LeafletModule,
    IconComponent,
    ToggleButtonComponent,
    TooltipModule,
    AccordionModule,
    MapToolbarComponent
  ]
})
export class MapComponent {
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private cookieService = inject(CookieService);
  private dialogManager = inject(DialogManagerService);
  private mapService = inject(MapService);
  destroyRef = inject(DestroyRef);
  ref: DynamicDialogRef;

  isLayerSubmenuVisible: boolean = false;
  iconRetinaUrl = 'assets/marker-icon-2x.png';
  iconUrl = 'assets/marker-icon.png';
  shadowUrl = 'assets/marker-shadow.png';
  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],

      iconUrl: 'assets/img/marker-icon.png',
      shadowUrl: 'assets/img/marker-shadow.png'
    })
  };
  options: L.MapOptions = {
    layers: [L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { attribution: 'Open Street Map' })],
    zoom: 6,
    minZoom: 5,
    maxZoom: 17,
    center: new L.LatLng(environment.center[0], environment.center[1]),
    zoomControl: false
  };
  disabledAreas: string[] = [];
  map: L.Map = {} as L.Map;
  currentMarker: L.Marker;
  filterLayerTypes: FilterLayer[] = [];
  showFilters: boolean = false;
  groupedLayers: { [key: string]: L.LayerGroup } = {};

  constructor() {
    effect(
      () => {
        const mapResize = this.mapService.resizeMapSignal();
        if (mapResize) {
          setTimeout(() => {
            this.map.invalidateSize(true);
          }, 300);
        }
      },
      { allowSignalWrites: true }
    );
  }

  onMapReady(map: L.Map): void {
    this.map = map;
    this.displayCoordinates(map);
    setTimeout(() => {
      this.map.invalidateSize(true);
    }, 300);
  }

  zoomIn(): void {
    this.map.zoomIn();
  }

  zoomOut(): void {
    this.map.zoomOut();
  }

  toogleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  onSearch(): void {
    this.ref = this.dialogService.open(SearchMapDialogComponent, {
      header: this.translateService.instant('dialogs.searchMap.header'),
      contentStyle: { overflow: 'auto' },
      width: '40rem',
      baseZIndex: 10000
    });
    this.dialogManager.addDialog(this.ref);
    this.ref.onClose.subscribe((data: Coordinates) => {
      if (data) {
        this.addSearchMarker(data);
      }
      this.dialogManager.removeDialog(this.ref);
    });
  }

  trackByFn(index: number, layer: AirspaceElement): number {
    return layer.id;
  }

  protected clearAllLayers(): void {
    this.map.eachLayer((layer) => {
      if (layer instanceof L.FeatureGroup) {
        this.map.removeLayer(layer);
      }
    });
  }

  protected addMarker(latlng: L.LatLng): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    this.currentMarker = L.marker(latlng, this.markerIcon)
      .bindTooltip(`${latlng.lat.toFixed(4)} ${latlng.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
  }

  protected setLayerStyles(layer: L.GeoJSON, styles: Style, styleType: StyleType): void {
    if (styleType === StyleType.ACTIVATED) {
      layer.setStyle(styles);
    } else {
      const highlightStyles = setHiglightStyle(styles);
      layer.setStyle(highlightStyles);
    }
  }

  protected isPointInLayer(latLng: L.LatLng, layer: L.GeoJSON): boolean {
    if (layer.feature) {
      const feature = layer.feature;
      if (feature.type === 'Feature' && feature.geometry) {
        return this.checkGeometryType(latLng, feature.geometry);
      }
    }
    return false;
  }

  protected checkGeometryType(latLng: L.LatLng, geometry: GeoJSON.Geometry): boolean {
    if (geometry.type === 'Polygon') {
      try {
        const clickPoint = Turf.point([latLng.lng, latLng.lat]);
        const coordinates: Position[][] = geometry.coordinates as Position[][];

        const polygon = Turf.polygon(coordinates);
        return Turf.booleanPointInPolygon(clickPoint, polygon);
      } catch (error) {
        console.error('Error creating Polygon in Turf.js', error);
      }
    } else if (geometry.type === 'MultiPolygon') {
      try {
        const clickPoint = Turf.point([latLng.lng, latLng.lat]);
        const coordinates: Position[][][] = geometry.coordinates as Position[][][];

        for (const coords of coordinates) {
          const polygon = Turf.polygon(coords);
          if (Turf.booleanPointInPolygon(clickPoint, polygon)) {
            return true;
          }
        }
      } catch (error) {
        console.error('Error creating MultiPolygon in Turf.js', error);
      }
    }
    return false;
  }

  protected convertGeometry(geometry: Geometry[]): Geometry[] | Geometry {
    if (geometry.length > 1) {
      return {
        type: 'MultiPolygon',
        coordinates: geometry.map((geo) => geo.coordinates) as Position[][]
      };
    } else {
      return geometry[0];
    }
  }

  protected bringToFrontLayers(types: MapLayerTypeEnum[]): void {
    types.forEach((type) => {
      const group = this.groupedLayers[type];
      if (group) {
        group.eachLayer((layer) => {
          if (layer instanceof L.GeoJSON) {
            layer.bringToFront();
          }
        });
      }
    });
  }

  protected toggleLayerGroupVisibility(visible: boolean, groupId: string): void {
    const group = this.groupedLayers[groupId];
    const filterGroupIndex = this.filterLayerTypes.findIndex((el) => el.type === groupId);
    if (visible) {
      this.filterLayerTypes[filterGroupIndex].checked = true;
      if (group) {
        group.addTo(this.map);
      }
    } else {
      this.filterLayerTypes[filterGroupIndex].checked = false;
      if (group) {
        group.remove();
      }
    }
  }

  protected moveCoordinatesDiv(showFilters: boolean): void {
    const container = document.getElementById('coordinatesContainer');
    if (container) {
      if (showFilters) {
        container.style.right = `6.5rem`;
        container.style.bottom = `5.3rem`;
      } else {
        container.style.right = `0rem`;
        container.style.bottom = `1rem`;
      }
    }
  }

  protected resetLayerStyles(): void {
    Object.values(this.groupedLayers).forEach((group) => {
      group.eachLayer((l) => {
        if (l instanceof L.GeoJSON) {
          const styles = (l.options as WebrqaGeoJSONOptions | AupGeoJSONOptions).styles;
          this.setLayerStyles(l, styles, StyleType.ACTIVATED);
        }
      });
    });
  }

  private addSearchMarker(latLng: Coordinates): void {
    if (this.currentMarker) {
      this.map.removeLayer(this.currentMarker);
    }
    const latLngConverted = new L.LatLng(latLng.latitude, latLng.longitude);
    this.currentMarker = L.marker(latLngConverted, this.markerIcon)
      .bindTooltip(`${latLngConverted.lat.toFixed(4)} ${latLngConverted.lng.toFixed(4)}`, {
        permanent: true,
        direction: 'right'
      })
      .addTo(this.map);
    this.map.setView(latLngConverted, 10);
  }

  private displayCoordinates(map: L.Map): void {
    const Coordinates = L.Control.extend({
      onAdd: (mapCoordinates: L.Map) => {
        const container = L.DomUtil.create('div', 'coordinates-container');
        container.setAttribute('id', 'coordinatesContainer');
        mapCoordinates.addEventListener('mousemove', (e: L.LeafletMouseEvent) => {
          container.innerHTML = `
            <div class='latlngInfo'>
            <strong>${e.latlng.lat.toFixed(4)} ${e.latlng.lng.toFixed(4)}</strong>
            </div>
            `;
        });
        return container;
      }
    });
    map.addControl(new Coordinates({ position: 'bottomright' }));
  }

  private setDisabledAreas(): void {
    this.cookieService.set(
      'inactiveAreas',
      JSON.stringify(Array.from(new Set(this.disabledAreas))),
      365,
      '/',
      '',
      true,
      'Strict'
    );
  }

  protected getDisabledAreas(): void {
    this.disabledAreas = this.cookieService.get('inactiveAreas')
      ? Array.from(new Set(JSON.parse(this.cookieService.get('inactiveAreas'))))
      : [];
  }
}
