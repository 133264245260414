<div class="time-bar p-2 mx-2">
  <div class="flex justify-content-between">
    <h3 class="mt-0">{{ 'map.filters.timeRange' | translate }}</h3>
    <h4 class="mt-0">UTC {{ formattedStartTime }} - {{ formattedEndTime }}</h4>
  </div>
  <div class="slider p-2">
    <p-slider
      [(ngModel)]="timeRange"
      [range]="true"
      [min]="0"
      [max]="1440"
      [step]="15"
      styleClass="w-full"
      (onChange)="onTimeRangeChange()"
      (onSlideEnd)="onTimeRangeChangeComplete(true)"
    />
    <div class="relative">
      @if (tooltipsVisible) {
        <div class="slider-tooltip" [style.left.%]="tooltipPositionMin" [style.transform]="'translateX(-50%)'">
          {{ formattedStartTime }}
        </div>
        <div class="slider-tooltip" [style.left.%]="tooltipPositionMax" [style.transform]="'translateX(-50%)'">
          {{ formattedEndTime }}
        </div>
      }
    </div>
  </div>
</div>
