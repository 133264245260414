import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AupMapLayer, MapLayerTypeEnum } from 'src/app/model/map.model';
import { layersStyle, setStyleAdditionalLayer } from 'src/app/map/layersStyle';
import { AupMinMaxValues, AupReservation, AupReservationFilters } from 'src/app/model/observer.model';
import { Altitudes } from 'src/app/model/altitudes.model';
import { normalizeAupTime } from 'src/app/shared/utils/time.utils';
@Injectable({
  providedIn: 'root'
})
export class AupMapService {
  private http = inject(HttpClient);
  readonly API_URL: string = '/api/airspace/aup';
  readonly layerStyles = layersStyle;
  allMapLayers: AupMapLayer[] = [];
  mapLayers: WritableSignal<AupMapLayer[]> = signal([]);

  fetchAupMapLayers(date: string, mapType: MapLayerTypeEnum): Observable<AupMapLayer[]> {
    return this.http.get<AupMapLayer[]>(`${this.API_URL}/${date}`).pipe(
      tap((layers) => {
        const aupMapLayers: AupMapLayer[] = [];
        layers.map((layer) => {
          const styles = layersStyle[layer.type];
          if (layer.reservations.length) {
            aupMapLayers.push({
              ...layer,
              ...this.getMinValuesFromReservations(layer.reservations),
              type: mapType,
              styles: setStyleAdditionalLayer(styles)
            });
          } else {
            aupMapLayers.push({
              ...layer,
              reservations: [],
              styles: styles
            });
          }
        });

        this.allMapLayers = aupMapLayers;
        this.mapLayers.set(aupMapLayers);
      })
    );
  }

  filterMapLayers(filters: AupReservationFilters | null): void {
    if (filters) {
      const filteredLayers = this.allMapLayers.filter((layer) => {
        const matchesDesignator = filters.designator?.length === 0 || filters.designator?.includes(layer.designator);
        const matchesFromUntil = this.matchesFromUntil(layer, filters);
        const matchesFlMinMax = this.matchesFlMinMax(layer, filters);
        return matchesDesignator && matchesFromUntil && matchesFlMinMax;
      });
      this.mapLayers.set(filteredLayers);
    } else {
      this.mapLayers.set(this.allMapLayers);
    }
  }

  private getMinValuesFromReservations(reservations: AupReservation[]): AupMinMaxValues {
    const result = reservations.reduce(
      (acc, reservation: AupReservation) => {
        if (
          !acc.flMin ||
          Altitudes[reservation.flMin as keyof typeof Altitudes] < Altitudes[acc.flMin as keyof typeof Altitudes]
        ) {
          acc.flMin = reservation.flMin;
        }

        if (
          !acc.flMax ||
          Altitudes[reservation.flMax as keyof typeof Altitudes] > Altitudes[acc.flMax as keyof typeof Altitudes]
        ) {
          acc.flMax = reservation.flMax;
        }

        return acc;
      },
      {
        flMin: '',
        flMax: ''
      }
    );
    return result;
  }

  private matchesFlMinMax(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    const flMinFilter = filters.flMin ? Altitudes[filters.flMin as keyof typeof Altitudes] : Altitudes['SFC'];
    const flMaxFilter = filters.flMax ? Altitudes[filters.flMax as keyof typeof Altitudes] : Altitudes['UNL'];

    const itemFlMin = Altitudes[layer.flMin as keyof typeof Altitudes];
    const itemFlMax = Altitudes[layer.flMax as keyof typeof Altitudes];
    if (layer.type === 'SECTOR') {
      return true;
    }
    return (
      (itemFlMin >= flMinFilter && itemFlMin <= flMaxFilter) || (itemFlMax >= flMinFilter && itemFlMax <= flMaxFilter)
    );
  }

  private matchesFromUntil(layer: AupMapLayer, filters: AupReservationFilters): boolean {
    const filterFrom = filters.from ? filters.from.replace(/_/g, '0') : '06:00';
    const filterUntil = filters.until ? filters.until.replace(/_/g, '0') : '06:00';
    if (filterFrom === '06:00' && filterUntil === '06:00') return true;
    let matchesFromUntil = true;

    const filterFromMinutes = normalizeAupTime(filterFrom);
    const filterUntilMinutes = normalizeAupTime(filterUntil);

    if (layer.reservations.length > 0) {
      matchesFromUntil = layer.reservations.some((reservation) => {
        if (reservation.from === '06:00' && reservation.until === '06:00') return true;
        const fromMinutes = normalizeAupTime(reservation.from);
        const untilMinutes = normalizeAupTime(reservation.until);
        return (
          (fromMinutes >= filterFromMinutes && fromMinutes <= filterUntilMinutes) ||
          (untilMinutes >= filterFromMinutes && untilMinutes <= filterUntilMinutes)
        );
      });
    } else {
      if (layer.intervals.length === 0) return true;
      matchesFromUntil = layer.intervals.some((interval) => {
        if (interval.startTime === '06:00' && interval.endTime === '06:00') return true;
        const startTimeMinutes = normalizeAupTime(interval.startTime);
        const endTimeMinutes = normalizeAupTime(interval.endTime);
        return (
          (startTimeMinutes >= filterFromMinutes && startTimeMinutes <= filterUntilMinutes) ||
          (endTimeMinutes >= filterFromMinutes && endTimeMinutes <= filterUntilMinutes)
        );
      });
    }

    return matchesFromUntil;
  }
}
