import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ReservationService } from 'src/app/services/reservation.service';

@Component({
  selector: 'rqa-holidays-info',
  templateUrl: './holidays-info.component.html',
  styleUrl: './holidays-info.component.scss',
  imports: [CommonModule, ButtonModule],
  standalone: true
})
export class HolidaysInfoComponent {
  showInfo: boolean = true;
  private reservationService = inject(ReservationService);
  holidaysInfo = this.reservationService.holidaysInfo;

  closeInfo(): void {
    this.showInfo = false;
    this.reservationService.holidaysInfo.set(null);
  }
}
