import { map, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { AupDetails, AupItem, AupReservation, AupReservationFilters } from '../model/observer.model';
import { SortEvent } from 'primeng/api';
import { altitudeArrayObserver, Altitudes } from '../model/altitudes.model';
import { AupMapService } from '../pages/observer/aup-details/aup-map/aup-map.service';
import { normalizeAupTime } from '../shared/utils/time.utils';

const url = `/api/uup`;

@Injectable({
  providedIn: 'root'
})
export class ObserverService {
  private http = inject(HttpClient);
  private aupMapService = inject(AupMapService);
  aupDetails: WritableSignal<AupDetails> = signal({
    dateFrom: '',
    dateTo: '',
    name: 'AUP',
    status: '',
    reservationShortDTOS: []
  });
  filtersSignal: WritableSignal<AupReservationFilters> = signal({
    designator: [],
    responsibleUnit: [],
    flMin: altitudeArrayObserver[0],
    flMax: altitudeArrayObserver[altitudeArrayObserver.length - 1],
    from: '06:00',
    until: '06:00'
  });

  sortSignal: WritableSignal<SortEvent | null> = signal(null);

  filteredReservation: Signal<AupReservation[]> = computed(() => {
    const filters = this.filtersSignal();
    const sort = this.sortSignal();
    return this.filterAndSortReservation(this.aupDetails().reservationShortDTOS, filters, sort);
  });

  fetchAupList(): Observable<AupItem[]> {
    return this.http.get<AupItem[]>(`${url}/getAupLastStatus`);
  }

  fetchAupDetails(dates: { localDate: string }[]): Observable<AupDetails> {
    return this.http.post<AupDetails[]>(`${url}/findUpByDates`, dates).pipe(
      tap((details) => {
        this.aupDetails.set(details[0]);
      }),
      map((res: AupDetails[]) => res[0])
    );
  }

  filterAndSortReservation(
    reservations: AupReservation[],
    filters: AupReservationFilters,
    sort: SortEvent | null
  ): AupReservation[] {
    let filtered = reservations.filter((item) => {
      const matchesDesignator =
        filters.designator && filters.designator.length ? filters.designator.includes(item.designator) : true;
      const matchesUnit =
        filters.responsibleUnit && filters.responsibleUnit.length
          ? filters.responsibleUnit.includes(item.responsibleUnit)
          : true;
      const matchesFromUntil = this.matchesFromUntil(item, filters);
      const matchesFlMinMax = this.matchesFlMinMax(item, filters);

      return matchesDesignator && matchesUnit && matchesFlMinMax && matchesFromUntil;
    });

    // Sortowanie
    if (sort && sort.field) {
      filtered = this.sortReservations(filtered, sort.field as keyof AupReservation, sort.order);
    }
    return filtered;
  }

  // Sortowanie
  sortReservations(
    reservations: AupReservation[],
    column: keyof AupReservation,
    direction: number | undefined
  ): AupReservation[] {
    return reservations.sort((a, b) => {
      let valueA: any; // eslint-disable-line
      let valueB: any; // eslint-disable-line

      switch (column) {
        case 'flMin':
          valueA = Altitudes[a.flMin as keyof typeof Altitudes];
          valueB = Altitudes[b.flMin as keyof typeof Altitudes];
          break;
        case 'flMax':
          valueA = Altitudes[a.flMax as keyof typeof Altitudes];
          valueB = Altitudes[b.flMax as keyof typeof Altitudes];
          break;

        default:
          valueA = (a as any)[column]; // eslint-disable-line
          valueB = (b as any)[column]; // eslint-disable-line
      }

      const comparison = valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      return direction === 1 ? comparison : -comparison;
    });
  }

  updateFilters(filters: AupReservationFilters | null) {
    this.aupMapService.filterMapLayers(filters);

    if (filters) {
      this.filtersSignal.set(filters);
    } else {
      this.filtersSignal.set({
        designator: [],
        responsibleUnit: [],
        flMin: altitudeArrayObserver[0],
        flMax: altitudeArrayObserver[altitudeArrayObserver.length - 1],
        from: '06:00',
        until: '06:00'
      });
    }
  }

  updateSort(sort: SortEvent | null) {
    this.sortSignal.set(sort);
  }

  private matchesFlMinMax(reservation: AupReservation, filters: AupReservationFilters): boolean {
    const flMinFilter = filters.flMin ? Altitudes[filters.flMin as keyof typeof Altitudes] : Altitudes['SFC'];
    const flMaxFilter = filters.flMax ? Altitudes[filters.flMax as keyof typeof Altitudes] : Altitudes['UNL'];

    const itemFlMin = Altitudes[reservation.flMin as keyof typeof Altitudes];
    const itemFlMax = Altitudes[reservation.flMax as keyof typeof Altitudes];
    return (
      (itemFlMin >= flMinFilter && itemFlMin <= flMaxFilter) || (itemFlMax >= flMinFilter && itemFlMax <= flMaxFilter)
    );
  }

  private matchesFromUntil(reservation: AupReservation, filters: AupReservationFilters): boolean {
    const filterFrom = filters.from ? filters.from.replace(/_/g, '0') : '06:00';
    const filterUntil = filters.until ? filters.until.replace(/_/g, '0') : '06:00';
    if (
      (filterFrom === '06:00' && filterUntil === '06:00') ||
      (reservation.from === '06:00' && reservation.until === '06:00')
    )
      return true;

    const filterFromMinutes = normalizeAupTime(filterFrom);
    const filterUntilMinutes = normalizeAupTime(filterUntil);
    const fromMinutes = normalizeAupTime(reservation.from);
    const untilMinutes = normalizeAupTime(reservation.until);
    return (
      (fromMinutes >= filterFromMinutes && fromMinutes <= filterUntilMinutes) ||
      (untilMinutes >= filterFromMinutes && untilMinutes <= filterUntilMinutes)
    );
  }
}
