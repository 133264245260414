<div class="map-container">
  <rqa-map-toolbar [filterLayerTypes]="filterLayerTypes" [disabledAreas]="disabledAreas"></rqa-map-toolbar>
  <div class="selected-layers-box">
    <rqa-selected-map-layers [layers]="layersContainingPoint"></rqa-selected-map-layers>
  </div>

  <div
    id="map"
    leaflet
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    (leafletClick)="onMapClick($event)"
  ></div>
  @if (showFilters) {
    <div class="time-container">
      <rqa-map-time [filterTimeRange]="timeRange" (timeRangeChange)="onTimeRangeChange($event)"></rqa-map-time>
    </div>
    <div class="height-container">
      <rqa-map-height
        [filterHeightRange]="heightRange"
        (heightRangeChange)="onHeightRangeChange($event)"
      ></rqa-map-height>
    </div>
  }
</div>
