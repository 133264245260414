import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { AupMapService } from './aup-map.service';
import { AupMapLayer, MapLayerTypeEnum } from 'src/app/model/map.model';
import { ObserverService } from 'src/app/services/observer.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Route } from 'src/app/model/route.enum';

@Injectable({
  providedIn: 'root'
})
export class AupMapResolver implements Resolve<AupMapLayer[]> {
  constructor(
    private aupMapService: AupMapService,
    private observerService: ObserverService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<AupMapLayer[]> {
    this.observerService.updateFilters(null);
    this.observerService.updateSort(null);
    const mapType = route.routeConfig?.path?.includes(Route.AUP) ? MapLayerTypeEnum.AUP : MapLayerTypeEnum.UUP;
    const date = route.params['date'];
    return this.aupMapService.fetchAupMapLayers(date, mapType);
  }
}
