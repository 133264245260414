import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { BadgeModule } from 'primeng/badge';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { OrdersService } from 'src/app/services/orders.service';
import { RqaReservationChangeDTO, RqaReservationDTO } from 'src/app/model/reservation.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Calendar, CalendarModule } from 'primeng/calendar';
import { SelectButtonChangeEvent, SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from 'src/app/ui/icon/icon.component';
import { OrderStatus, OrderStatusOptions } from 'src/app/model/orders';
import * as moment from 'moment';
import { ConfirmationService, FilterService, MessageService } from 'primeng/api';
import { Route } from 'src/app/model/route.enum';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { Router, RouterModule } from '@angular/router';
import { DateService } from 'src/app/services/date.service';
import { MapLayerTypeEnum } from 'src/app/model/map.model';
import { RqaMapService } from '../../rqa-order/rqa-map/rqa-map.service';
import { DATE_TIME_FORMAT } from 'src/app/shared/constants/date.constants';
import { UserConfigService } from 'src/app/services/user-config.service';
import { Table, TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';

interface Column {
  field: string;
  header: string;
  type: 'string' | 'date';
}

@Component({
  selector: 'rqa-sent-orders',
  templateUrl: './sent-orders.component.html',
  styleUrls: ['./sent-orders.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    BadgeModule,
    DividerModule,
    ButtonModule,
    TranslateModule,
    CalendarModule,
    SelectButtonModule,
    IconComponent,
    TableModule,
    RouterModule,
    CheckboxModule,
    OverlayPanelModule
  ],
  providers: [RouterModule]
})
export class SentOrdersComponent implements OnInit {
  @ViewChild('dt') pTable!: Table;
  @ViewChild('calendar') pCalendar!: Calendar;
  private ordersService = inject(OrdersService);
  private messageService = inject(MessageService);
  private translateService = inject(TranslateService);
  private confirmationService = inject(ConfirmationService);
  private filterService = inject(FilterService);
  private dateService = inject(DateService);
  private router = inject(Router);
  private rqaMapService = inject(RqaMapService);
  private userConfig = inject(UserConfigService);
  orders: RqaReservationDTO[];
  dateRange: Date[] = [];
  orderFilterStatus: OrderStatus = OrderStatus.SENT;
  dateFormat: string = 'yy-mm-dd 06:00';
  orderOptions: OrderStatusOptions[] = [
    {
      label: this.translateService.instant('buttons.sent'),
      value: OrderStatus.SENT
    },
    {
      label: this.translateService.instant('buttons.canceled'),
      value: OrderStatus.CANCELLED
    }
  ];
  selectedOrders: RqaReservationDTO[] = [];
  selectedOrderOption: OrderStatus = OrderStatus.SENT;
  OrderStatus = OrderStatus;
  cols: Column[] = [
    { field: 'designator', header: this.translateService.instant('table.header.designator'), type: 'string' },
    { field: 'startDate', header: this.translateService.instant('table.header.startDate'), type: 'date' },
    { field: 'endDate', header: this.translateService.instant('table.header.endDate'), type: 'date' },
    { field: 'lowerAltitude', header: this.translateService.instant('table.header.lowerAltitude'), type: 'string' },
    { field: 'upperAltitude', header: this.translateService.instant('table.header.upperAltitude'), type: 'string' },
    { field: 'activityType', header: this.translateService.instant('table.header.activityType'), type: 'string' }
  ];

  ngOnInit(): void {
    this.getSentOrders();
    this.configureFilterService();
    this.setFilterDateFormat();

    this.translateService.onLangChange.subscribe(() => {
      this.orderOptions = [
        {
          label: this.translateService.instant('buttons.sent'),
          value: OrderStatus.SENT
        },
        {
          label: this.translateService.instant('buttons.canceled'),
          value: OrderStatus.CANCELLED
        }
      ];
    });
  }

  filterByStatus(event: SelectButtonChangeEvent): void {
    if (event.value === OrderStatus.CANCELLED) {
      this.rqaMapService.updateMapLayers([], MapLayerTypeEnum.ORDER);
      this.router.navigate([Route.CANCELED_ORDERS]);
    }
  }

  filterByDate(): void {
    if (this.dateRange.length) {
      const startDate = this.dateService.parseIsoDateToString(
        moment(this.dateRange[0]).set({ hour: 6, minute: 0 }).format(DATE_TIME_FORMAT)
      );
      this.dateRange[1] = this.dateService.parseIsoDateToDateObj(
        moment(startDate).add(1, 'days').format(DATE_TIME_FORMAT)
      );
      const endDate = this.dateService.parseIsoDateToString(
        moment(this.dateRange[1]).set({ hour: 6, minute: 0 }).format(DATE_TIME_FORMAT)
      );
      this.pTable.filter(startDate, 'startDate', 'dateSameAfter');
      this.pTable.filter(endDate, 'endDate', 'dateSameBefore');
      this.pCalendar.overlayVisible = false;
    }
  }

  onOrdersFilter(): void {
    if (this.pTable.filteredValue?.length) {
      const designators = this.pTable.filteredValue.map((el) => el.designator);
      this.rqaMapService.updateMapLayers(designators, MapLayerTypeEnum.ORDER);
    } else {
      this.rqaMapService.updateMapLayers([], MapLayerTypeEnum.ORDER);
    }
  }

  clearFilters(): void {
    this.dateRange = [];
    this.pTable.filters = {};
    this.pTable._filter();
    const designators = this.orders.map((el) => el.designator);
    this.rqaMapService.updateMapLayers(designators, MapLayerTypeEnum.ORDER);
  }

  onRemoveSelected(): void {
    this.confirmationService.confirm({
      acceptLabel: this.translateService.instant('dialogs.confirm'),
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: this.translateService.instant('dialogs.reject'),
      header: this.translateService.instant('dialogs.deleteReservation.header'),
      message: this.translateService.instant('dialogs.deleteReservation.msg'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const payload: string[] = this.getSubReservationsForRemove();
        this.ordersService.cancelReservations(payload).subscribe({
          next: (res) => {
            this.messageService.add({
              severity: 'success',
              detail: res.message,
              life: 3000
            });
            this.getSentOrders();
            this.selectedOrders = [];
          },
          error: () => {
            this.messageService.add({
              severity: 'error',
              detail: this.translateService.instant('notifications.deleteError'),
              life: 3000
            });
          }
        });
      }
    });
  }

  onEditSelected(): void {
    const payload = this.getReservationsForEdit();
    this.ordersService.editSentOrders(payload).subscribe({
      next: () => {
        this.router.navigate([Route.HOME]);
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant('notifications.deleteTemplateError'),
          life: 3000
        });
      }
    });
  }

  getAupFile(): void {
    const aupDate = moment(this.dateRange[0]).format('YYYY-MM-DD');
    this.ordersService.getFile(aupDate).subscribe((value) => {
      this.downloadFile(value.body, 'reservations_' + aupDate);
    });
  }

  showOrderHistory(order: RqaReservationDTO): void {
    const orderUuid = order.uuid;
    const subOrderUuid = order.subReservationDTOList[0].uuid;
    this.getReservationHistory(orderUuid, subOrderUuid);
  }

  onAllOrderSelection(event: CheckboxChangeEvent) {
    this.selectedOrders = [];
    this.orders.forEach((order) => {
      order.selected = event.checked;
      order.subReservationDTOList.forEach((subOrder) => {
        subOrder.selected = event.checked;
      });
      if (event.checked) {
        this.selectedOrders.push(order);
      }
    });
  }

  onOrderSelection(event: CheckboxChangeEvent, order: RqaReservationDTO) {
    order.subReservationDTOList.forEach((subOrder) => {
      subOrder.selected = event.checked;
    });
    this.selectedOrders = this.orders.filter((el) => el.subReservationDTOList.some((subOrder) => subOrder.selected));
  }

  onSubOrderSelection(event: CheckboxChangeEvent, order: RqaReservationDTO) {
    if (event.checked) {
      order.selected = true;
    } else {
      const subOrdersSelected = order.subReservationDTOList.filter((el) => el.selected);
      if (!subOrdersSelected.length) {
        order.selected = false;
      }
    }
    this.selectedOrders = this.orders.filter((el) => el.subReservationDTOList.some((subOrder) => subOrder.selected));
  }

  private setFilterDateFormat(): void {
    const timeZone = this.userConfig.getTimezone();
    const hours = '06:00';
    const localHours = this.dateService.adjustTimeToLocal(hours);
    if (timeZone !== 'UTC') {
      this.dateFormat = 'yy-mm-dd ' + localHours;
    } else {
      this.dateFormat = 'yy-mm-dd ' + hours;
    }
  }

  private downloadFile(data: ArrayBuffer | null, filename: string): void {
    if (data) {
      const blob = new Blob([data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = filename;
      anchor.href = url;
      anchor.click();
    }
  }

  private getSentOrders(): void {
    this.ordersService.getSentReservations().subscribe((data) => {
      this.orders = this.mapOrdersToTable(data);
      this.rqaMapService.updateMapLayers(
        this.orders.map((el) => el.designator),
        MapLayerTypeEnum.ORDER
      );
    });
  }

  private getReservationHistory(orderUuid: string, subOrderUuid: string): void {
    const orderIdx = this.orders.findIndex((order) => order.uuid === orderUuid);
    if (this.orders[orderIdx].reservationHistory?.length) return;
    this.ordersService.getReservationHistory(subOrderUuid).subscribe((data) => {
      this.orders[orderIdx].reservationHistory = data;
    });
  }

  private mapOrdersToTable(orders: RqaReservationDTO[]): RqaReservationDTO[] {
    return orders.map((order) => {
      if (order?.subReservationDTOList.length) {
        const children = order.subReservationDTOList.filter((el) => el.status !== 'CANCELED');
        order.subReservationDTOList = [];
        return {
          ...order,
          startDate: this.dateService.parseIsoDateToString(order.startDate),
          endDate: this.dateService.parseIsoDateToString(order.endDate),
          subReservationDTOList: children.map((el) => ({
            ...el,
            startDate: this.dateService.parseIsoDateToString(el.startDate),
            endDate: this.dateService.parseIsoDateToString(el.endDate),
            designator: order.designator,
            lowerAltitude: order.lowerAltitude,
            upperAltitude: order.upperAltitude,
            activityType: order.activityType
          }))
        };
      }
      if (order?.subReservationDTOList.length === 1) {
        const children = order.subReservationDTOList.filter((el) => el.status !== 'CANCELED');
        return {
          ...order,
          startDate: this.dateService.parseIsoDateToString(children[0].startDate),
          endDate: this.dateService.parseIsoDateToString(children[0].endDate)
        };
      }
      return {
        ...order,
        startDate: this.dateService.parseIsoDateToString(order.startDate),
        endDate: this.dateService.parseIsoDateToString(order.endDate),
        uuid: order.subReservationDTOList[0].uuid,
        changed: true
      };
    }) as RqaReservationDTO[];
  }

  private getSubReservationsForRemove(): string[] {
    const uuids: string[] = [];
    this.selectedOrders.forEach((order) => {
      if (order.subReservationDTOList) {
        order.subReservationDTOList.forEach((subOrder) => {
          if (subOrder.selected) {
            uuids.push(subOrder.uuid);
          }
        });
      }
    });
    return uuids;
  }

  private getReservationsForEdit(): RqaReservationChangeDTO[] {
    return this.selectedOrders.map((el) => ({
      ...el,
      startDate: this.dateService.parseDateToReservationRow(el.startDate),
      endDate: this.dateService.parseDateToReservationRow(el.endDate),
      changed: true
    })) as RqaReservationChangeDTO[];
  }

  private configureFilterService(): void {
    this.filterService.register('dateSameAfter', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;

      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format(DATE_TIME_FORMAT) : null;
      return formattedDate ? moment(formattedDate).isSameOrAfter(moment(filter)) : true;
    });

    this.filterService.register('dateSameBefore', (value: string | Date, filter: string): boolean => {
      if (!filter?.trim()) return true;
      if (value === null) return false;

      const formattedDate =
        value instanceof Date || !isNaN(Date.parse(value)) ? moment(value).format(DATE_TIME_FORMAT) : null;
      return formattedDate ? moment(formattedDate).isSameOrBefore(moment(filter)) : true;
    });
  }
}
