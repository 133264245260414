@if (orders) {
  <p-table
    #dt
    [value]="orders"
    dataKey="uuid"
    [styleClass]="'pansa-table'"
    [scrollable]="true"
    scrollHeight="600px"
    [responsive]="true"
    responsiveLayout="stack"
    [breakpoint]="'960px'"
    (onFilter)="onOrdersFilter()"
  >
    <ng-template pTemplate="caption">
      <div class="flex justify-content-between align-items-center lg:flex-row flex-column">
        <p-selectButton
          [options]="orderOptions"
          optionLabel="label"
          optionValue="value"
          [(ngModel)]="orderFilterStatus"
          (onChange)="filterByStatus($event)"
        ></p-selectButton>
        <div>
          <p-calendar
            [(ngModel)]="dateRange"
            dateFormat="yy-mm-dd 06:00"
            [iconDisplay]="'input'"
            [showIcon]="true"
            selectionMode="range"
            [readonlyInput]="true"
            (onSelect)="filterByDate($event)"
            #calendar
          >
            <ng-template pTemplate="footer">
              <div class="flex justify-content-between">
                <p-button
                  [label]="'primeng.clear' | translate"
                  [text]="true"
                  icon="pi pi-times"
                  (click)="clearFilters()"
                ></p-button>
              </div>
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="text-xs">
        <th class="text-xs pl-3 pr-2" pSortableColumn="designator">
          {{ 'table.header.designator' | translate }} <p-sortIcon field="designator"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="startDate">
          {{ 'table.header.startDate' | translate }} <p-sortIcon field="startDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="endDate">
          {{ 'table.header.endDate' | translate }} <p-sortIcon field="endDate"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="lowerAltitude">
          {{ 'table.header.lowerAltitude' | translate }} <p-sortIcon field="lowerAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="upperAltitude">
          {{ 'table.header.upperAltitude' | translate }} <p-sortIcon field="upperAltitude"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2" pSortableColumn="activityType">
          {{ 'table.header.activityType' | translate }} <p-sortIcon field="activityType"></p-sortIcon>
        </th>
        <th class="text-xs pl-1 pr-2"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-expanded="expanded">
      <tr class="text-sm">
        <td class="p-1 pl-3">
          <span class="p-column-title">{{ 'table.header.designator' | translate }}</span
          >{{ order.designator }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.startDate' | translate }}</span
          >{{ order.startDate | date: 'yyyy-MM-dd HH:mm' }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.endDate' | translate }}</span
          >{{ order.endDate | date: 'yyyy-MM-dd HH:mm' }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.lowerAltitude' | translate }}</span
          >{{ order.lowerAltitude }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.upperAltitude' | translate }}</span
          >{{ order.upperAltitude }}
        </td>
        <td class="p-1">
          <span class="p-column-title">{{ 'table.header.activityType' | translate }}</span
          >{{ order.activityType }}
        </td>
        <td>
          <p-button
            type="button"
            pRipple
            (onClick)="op.toggle($event)"
            [text]="true"
            [rounded]="true"
            [plain]="true"
            icon="pi pi-calendar-clock"
          />
          <p-overlayPanel #op (onShow)="showOrderHistory(order)">
            <div *ngIf="order.reservationHistory?.length">
              <p-table [value]="order.reservationHistory">
                <ng-template pTemplate="header">
                  <tr class="text-xs">
                    <th>{{ 'orders.history.sentDate' | translate }}</th>
                    <th>{{ 'orders.history.cancelledDate' | translate }}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-reservationHistory>
                  <tr class="text-xs">
                    <td>{{ reservationHistory.sentDate | date: 'yyyy-MM-dd: HH:mm' }}</td>
                    <td>{{ reservationHistory.cancelledDate | date: 'yyyy-MM-dd: HH:mm' }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>
  </p-table>
}
